<template>
  <div>
    <!-- 区域饼状图 -->
    <div class="chart-component">
      <el-col>
        <shebycxxbarChartComponent/>
      </el-col>
    </div>

    <div style="margin: 10px 0">
      <span class="ml-5">设备类型：</span>
      <el-select style="width: 200px" placeholder="请选择设备类型" v-model="sheblx">
        <el-option v-for="item in deviceTypes"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"></el-option>
      </el-select>

      <span>异常时间：</span>
      <el-date-picker style="width: 200px" placeholder="请选择异常时间" class="ml-5" v-model="yicsj" value-format="yyyy-MM-dd"></el-date-picker>



      <span class="ml-5">异常原因：</span>
      <el-select style="width: 200px" placeholder="请选择异常原因" v-model="yicyy">
        <el-option v-for="item in causeOfTheAnomaly"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"></el-option>
      </el-select>

      <span class="ml-5">处理建议：</span>
      <el-select style="width: 200px" placeholder="请选择处理建议" v-model="chuljy">
        <el-option v-for="item in chuljyOptions"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"></el-option>
      </el-select>




      <el-button class="ml-5" type="primary" icon="el-icon-search" @click="load">查询</el-button>
      <el-button type="danger" icon="el-icon-warning-outline" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增数据 <i class="el-icon-circle-plus-outline"></i></el-button>
       <el-button type="danger" @click="delBatch">批量删除 <i class="el-icon-delete"></i></el-button>
      <el-upload action="https://lkwyznkj.cyou:9090/shebycxx/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入数据 <i class="el-icon-upload el-icon--right"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出数据 <i class="el-icon-download"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'TableHeader'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="sheblx" label="设备类型"></el-table-column>
      <el-table-column prop="shebmc" label="设备名称"></el-table-column>
      <el-table-column prop="shebid" label="设备ID"></el-table-column>
      <el-table-column prop="anzzb" label="安装坐标"></el-table-column>
      <el-table-column prop="anzwz" label="安装位置"></el-table-column>
      <el-table-column prop="yicyy" label="异常原因">
        <template #default="{ row }">
          <el-tag v-if="row.yicyy" :type="row.yicyy ? 'danger' : ''">{{ row.yicyy }}</el-tag>
          <span v-else>{{ row.yicyy }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="yicsj" label="异常时间"></el-table-column>
      <el-table-column prop="chuljy" label="处理建议">
        <template #default="{ row }">
          <el-tag v-if="row.chuljy" :type="row.chuljy ? 'success' : ''">{{ row.chuljy }}</el-tag>
          <span v-else>{{ row.chuljy }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作"  width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-button type="danger" @click="del(scope.row.id)">删除 <i class="el-icon-delete"></i></el-button>

        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 500px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="设备类型" prop="sheblx">
          <el-select v-model="form.sheblx" placeholder="请选择设备类型">
            <el-option
                v-for="item in deviceTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input v-model="form.shebmc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备ID">
          <el-input v-model="form.shebid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装坐标">
          <el-input v-model="form.anzzb" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装位置">
          <el-input v-model="form.anzwz" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="异常原因">
          <el-select v-model="form.yicyy" placeholder="请选择异常原因">
            <el-option
                v-for="item in causeOfTheAnomaly"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常时间" prop="yicsj">
          <el-date-picker
              v-model="form.yicsj"
              type="datetime"
              placeholder="选择异常时间"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理建议">
          <el-select v-model="form.chuljy" placeholder="请选择处理建议">
            <el-option
                v-for="item in chuljyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="el-icon-close" type="danger" @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="el-icon-check" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import shebycxxbarChartComponent from "@/components/shebycxxbarChartComponent.vue";

export default {
  name: "Shebycxx",
  components: {
    shebycxxbarChartComponent,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      sheblx: "",
      yicsj: "",
      chuljy: "",
      yicyy: "",
      form: {
        id: "",
        sheblx: "",
        shebmc: "",
        shebid: "",
        anzzb: "",
        anzwz: "",
        yicyy: "",
        yicsj: "",
        chuljy: "",
      },
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      deviceTypes: [
        { value: '定位基站', label: '定位基站' },
        { value: 'uwb标签', label: 'uwb标签' }
      ],
      causeOfTheAnomaly: [
        { value: '过载', label: '过载' },
        { value: '短路', label: '短路' },
        { value: '温度过高', label: '温度过高' },
        { value: '机械故障', label: '机械故障' },
        { value: '传感器故障', label: '传感器故障' },
        { value: '电源问题', label: '电源问题' }
      ],
      chuljyOptions: [
        { value: '重启设备', label: '重启设备' },
        { value: '检查电路线', label: '检查电路线' },
        { value: '修理元件或更换', label: '修理元件或更换' },
        { value: '调整参数', label: '调整参数' },
        { value: '联系技术支持', label: '联系技术支持' },
        { value: '执行维护操作', label: '执行维护操作' }
      ]
    }
  },
  created() {
    this.load()
  },
  methods: {
    reset() {
        this.sheblx = ""
        this.yicsj = ""
        this.chuljy = ""
        this.yicyy = ""
        // this.email = ""
        this.load()
      },
    load() {
      this.request.get("/shebycxx/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sheblx: this.sheblx,
          yicsj: this.yicsj,
          chuljy: this.chuljy,
          yicyy: this.yicyy,
          // email: this.email,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.request.post("/shebycxx", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      // this.$nextTick(() => {
       // if(this.$refs.img) {
        //   this.$refs.img.clearFiles();
       //  }
       //  if(this.$refs.file) {
       //   this.$refs.file.clearFiles();
       //  }
     // })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
     //  this.$nextTick(() => {
      //   if(this.$refs.img) {
       //    this.$refs.img.clearFiles();
      //   }
      //   if(this.$refs.file) {
       //   this.$refs.file.clearFiles();
      //   }
     //  })
    },
    del(id) {
          // 首先弹出确认框
          this.$confirm('您确定删除这条数据吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 用户确认删除后，发起删除请求
            return this.request.delete("/shebycxx/" + id).then(res => {
              if (res.code === '200') {
                this.$message.success("删除成功");
                // 删除成功后刷新数据
                this.load();
              } else {
                // 删除失败，抛出错误以便被捕获
                throw new Error("Server returned non-200 code.");
              }
            }).catch(error => {
              console.error("删除请求失败:", error);
              this.$message.error("删除失败，请检查网络或联系管理员。");
            });
          }).catch(() => {
            // 用户取消删除，显示信息
            this.$message.warning("删除操作已取消");
          });
        },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
           // 首先检查是否有多选的数据
          if (this.multipleSelection.length === 0) {
            this.$message.error("请至少选择一条数据！");
            // 如果没有选择任何数据，则直接返回，不执行后续操作
            return;
          }
          // 使用Promise链确保先弹出确认框，再根据用户选择执行删除操作
          this.$confirm('您确定批量删除选中的数据吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // 用户确认删除后，提取选中项ID并发起删除请求
            let ids = this.multipleSelection.map(v => v.id);
            // 发起POST请求删除选中项
            return this.request.post("/shebycxx/del/batch", ids).then(res => {
                  if (res.code === '200') {
                    this.$message.success("批量删除成功");
                    // 删除成功后刷新数据
                    this.load();
                  } else {
                    // 删除失败，但这里应该捕获到的是成功的resolve，故应调整逻辑或错误处理
                    throw new Error("Server returned non-200 code.");
                  }
                })
                // 捕获请求错误或逻辑错误
                .catch(error => {
                  console.error("批量删除请求失败:", error);
                  this.$message.error("批量删除失败，请检查网络或联系管理员。");
                });
          }).catch(() => {
            // 用户取消删除，显示信息
            this.$message.warning("批量删除操作已取消");
          });
        },

    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
   // handleFileUploadSuccess(res) {
   //   this.form.file = res
  //  },
  //  handleImgUploadSuccess(res) {
  //    this.form.img = res
  //  },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("https://lkwyznkj.cyou:9090/shebycxx/export")
      this.$message.success("导出成功")
      this.load()
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.TableHeader {
  background: #eee!important;
}
</style>
