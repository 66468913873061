<template>
  <div>
    <el-row :gutter="12" style="margin: 5px 0">
      <el-col :span="24">
        <el-card>
          <div style="width: 100%; height: 400px" id="bar"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
const SheBeiYiChangXinXi = {
  title: {
    text: '设备异常信息统计',
    left: 'center'
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    left: 'left'
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '总数',
      data: [],
      type: 'bar',
      smooth: true
    }
  ]
}
export default {
  name: 'shebycxxChartComponent',

  data() {
    return {

    };
  },
  mounted() {

    // 柱状图
    let barDom = document.getElementById('bar');
    let barChart = echarts.init(barDom);
    barChart.setOption(SheBeiYiChangXinXi)

    this.$request.get('/shebycxx/shebycxxBar').then(res => {
      // 柱状图
      SheBeiYiChangXinXi.xAxis.data = res.data?.bar?.map(v => v.name) || []
      SheBeiYiChangXinXi.series[0].data = res.data?.bar?.map(v => v.value) || []
      barChart.setOption(SheBeiYiChangXinXi)
    })
  },


  methods: {
  }
}
</script>

<style scoped>

</style>
