import { render, staticRenderFns } from "./shebycxxbarChartComponent.vue?vue&type=template&id=24497d1f&scoped=true"
import script from "./shebycxxbarChartComponent.vue?vue&type=script&lang=js"
export * from "./shebycxxbarChartComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24497d1f",
  null
  
)

export default component.exports